import React, { FC, lazy, Suspense, useMemo } from "react"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { Position, Spinner } from "@blueprintjs/core"
import {
  notifyIfManualAuth,
  useAuth,
  withAuthenticationRequired,
} from "@subscale/formlogic-auth-ts"
import DebounceLink from "apollo-link-debounce"
import { createBrowserHistory } from "history"

import { Nav } from "src/components/Nav/Nav"
import MainCanvasProvider from "src/hooks/transferCanvas/useMainCanvas"
import { ToasterProvider } from "src/hooks/useToaster"
import { Account } from "src/pages/Account/Account"
import { Jobs } from "src/pages/Jobs/Jobs"
import PartLibrary from "src/pages/PartLibrary/PartLibrary"
import { ActiveProbingThunkContextProvider } from "src/store/cam/storedPlanThunks"
import { Websocket } from "./components/Websocket/Websocket"
import { ViewCubeCallbackProvider } from "./hooks/useViewCubeCallback"
import { StockConfigEditor } from "./pages/ConfigEditors/StockConfigEditor/StockConfigEditor"
import { Docs } from "./pages/Docs/Docs"
import { DrawingViewer } from "./pages/DrawingViewer/DrawingViewer"
import { JiraCallbackPage2 } from "./pages/JiraCallbackPage2/JiraCallbackPage2"
import { OpSceneView } from "./pages/OpSceneView/OpSceneView"
import { PlanHistory } from "./pages/PlanHistory/PlanHistory"
import { Sessions } from "./pages/Sessions/Sessions"
import SetupSheet from "./pages/SetupSheet/SetupSheet"
import { QueryParamsListener } from "./pages/ViewerPage/Listeners/ActivePlanOperationListener"
import { WorkerStats } from "./pages/WorkerStats/WorkerStats"

import "./App.css"
import styles from "./App.module.css"

const ReportPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Report" */
      "src/pages/ReportPage/ReportPage"
    )
)

const ToolLibraryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ToolLibrary" */
      "src/pages/ToolLibraryPage/ToolLibraryPage"
    )
)

const ToolLibraryDiffPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ToolLibrary" */
      "src/pages/ToolLibraryDiffPage/ToolLibraryDiffPage"
    )
)

const ReleaseNotesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReleaseNotes" */
      "src/pages/ReleaseNotesPage/ReleaseNotesPage"
    )
)

const CustomFixturesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomFixtures" */
      "src/pages/CustomFixturesPage/CustomFixturesPage"
    )
)

const FixturesConfigEditor = lazy(
  () =>
    import(
      /* webpackChunkName: "FixturesConfigEditor" */ "src/pages/ConfigEditors/FixturesConfigEditor/FixturesConfigEditor"
    )
)

const PlanFeedbackAnnotation = lazy(
  () =>
    import(
      /* webpackChunkName: "PlanFeedbackAnnotation" */
      "./pages/PlanFeedbackAnnotation/PlanFeedbackAnnotation"
    )
)

const ThumbnailViewerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ThumbnailViewerPage" */
      "src/pages/ThumbnailViewerPage/ThumbnailViewerPage"
    )
)

const ReportedIssuesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportedIssuesPage" */
      "src/pages/ReportedIssuesPage/ReportedIssuesPage"
    )
)

const FixturesConfigViewerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FixturesConfigViewerPage" */ "src/pages/FixturesConfigViewer/FixturesConfigViewerPage"
    )
)

const JobOverview = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "JobOverview" */ "src/pages/JobOverview/JobOverview"
    )
)

const GCodeEditor = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "GCodeEditor" */ "src/pages/GCodeEditor/GCodeEditor"
    )
)

const GCodeViewer = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "GCodeViewer" */ "src/pages/GCodeViewer/GCodeViewer"
    )
)

const GCodeDiff = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "GCodeDiff" */ "src/pages/GCodeDiff/GCodeDiff"
    )
)

const GCodeSetup = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "JobOverview" */ "src/pages/GCodeSetup/GCodeSetup"
    )
)

const Download = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Download" */ "src/pages/Download/Download"
    )
)

const UnbubbledDrawings = lazy(
  () =>
    import(
      /* webpackChunkName: "UnbubbledDrawings" */
      "src/pages/UnbubbledDrawings/UnbubbledDrawings"
    )
)

const DEFAULT_DEBOUNCE_TIMEOUT = 300

const App: FC = () => {
  const { manualAuth } = useAuth()
  useMemo(() => {
    manualAuth && notifyIfManualAuth()
  }, [manualAuth])

  return manualAuth ? <Auth0NotRequiredApp /> : <Auth0RequiredApp />
}

export default App

const history = createBrowserHistory()

const Auth0NotRequiredApp: FC = () => {
  const { getAccessTokenSilently } = useAuth()

  const httpLink = createHttpLink({
    uri: `/graphql`,
  })

  // NOTE: The need to comment these is due to DebounceLink not providing error: and onError;
  //  seems like it some minor tweaks to be fully compatible with the current version of Apollo
  //
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const link = ApolloLink.from([new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT), httpLink])

  const authLink = setContext(
    (_req, { headers }) =>
      new Promise((resolve, reject) => {
        getAccessTokenSilently()
          .then(result => {
            resolve({
              headers: {
                ...(headers as Record<string, unknown>),
                authorization: result ? `Bearer ${result}` : null,
              },
            })
          })
          .catch(error => reject(error))
      })
  )

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache({
      typePolicies: {
        // Prevent using id filed as graphql cache keys of tool objects, as these are not unique.
        ToolInfo: { keyFields: false },
        Model: { keyFields: false },
        Sor: { keyFields: false },
      },
    }),
  })

  return (
    <ApolloProvider client={client}>
      <ToasterProvider position={Position.BOTTOM}>
        <ViewCubeCallbackProvider>
          <ActiveProbingThunkContextProvider>
            <MainCanvasProvider
              gl={{ alpha: false, premultipliedAlpha: false }}
              onCreated={({ gl }) => {
                gl.setClearColor("#ffffff", 0)
              }}
            >
              <QueryParamsListener />
              <Websocket />
              <Router history={history}>
                <div className={styles.container}>
                  <Nav />
                  <Suspense fallback={<Spinner />}>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/cam-jobs" />
                      </Route>
                      <Route exact path="/overview/:jobId">
                        <JobOverview />
                      </Route>
                      <Route exact path="/download">
                        <Download />
                      </Route>
                      <Route exact path="/jobs/:jobUri">
                        <JobOverview />
                      </Route>
                      <Route exact path="/nc-edit/:planId/:operationIdx/:ncFileId">
                        <GCodeEditor />
                      </Route>
                      <Route exact path="/nc-view/">
                        <GCodeViewer />
                      </Route>
                      <Route exact path="/nc-diff/">
                        <GCodeDiff />
                      </Route>
                      <Route exact path="/nc-setup/:planId/:operationIdx/:ncFileId/:jobUri">
                        <GCodeSetup />
                      </Route>
                      <Route exact path="/drawing/:drawingId">
                        <DrawingViewer showControls />
                      </Route>
                      <Route exact path="/cam-jobs">
                        <Jobs />
                      </Route>
                      <Route exact path="/setup-sheet/:manifestUri+">
                        <SetupSheet />
                      </Route>
                      <Route exact path="/parts-library">
                        <PartLibrary />
                      </Route>
                      <Route exact path="/plan-feedback">
                        <PlanFeedbackAnnotation />
                      </Route>
                      <Route exact path="/3d-viewer">
                        <ThumbnailViewerPage />
                      </Route>
                      <Route exact path="/account">
                        <Account />
                      </Route>
                      <Route exact path="/stock-config">
                        <StockConfigEditor />
                      </Route>
                      <Route exact path="/fixtures-config">
                        <FixturesConfigEditor />
                      </Route>
                      <Route exact path="/fixtures-config-viewer">
                        <FixturesConfigViewerPage />
                      </Route>
                      <Route exact path="/workers-status">
                        <WorkerStats />
                      </Route>
                      <Route exact path="/sessions">
                        <Sessions />
                      </Route>
                      <Route exact path="/plan-history/:planId">
                        <PlanHistory />
                      </Route>
                      <Route exact path="/reported-issues">
                        <ReportedIssuesPage />
                      </Route>
                      <Route exact path="/docs/:redirectUri+">
                        <Docs />
                      </Route>
                      <Route exact path="/tool-library">
                        <ToolLibraryPage />
                      </Route>
                      <Route exact path="/tool-library-diff">
                        <ToolLibraryDiffPage />
                      </Route>
                      <Route exact path="/release-notes">
                        <ReleaseNotesPage />
                      </Route>
                      <Route exact path="/opscene/:planId/:operationIdx">
                        <OpSceneView />
                      </Route>
                      <Route exact path="/report">
                        <ReportPage />
                      </Route>
                      <Route exact path="/callback-jira2">
                        <JiraCallbackPage2 />
                      </Route>
                      <Route exact path={"/unbubbled-drawings"}>
                        <UnbubbledDrawings />
                      </Route>
                      <Route exact path={"/custom-fixtures"}>
                        <CustomFixturesPage />
                      </Route>
                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </Suspense>
                </div>
              </Router>
            </MainCanvasProvider>
          </ActiveProbingThunkContextProvider>
        </ViewCubeCallbackProvider>
      </ToasterProvider>
    </ApolloProvider>
  )
}

const Auth0RequiredApp = withAuthenticationRequired(Auth0NotRequiredApp)
